import menu from './scripts/menu'
import { lazyImages, objectFitImages } from './scripts/images'

import css from './css/style.css'

const start = () => {
	menu()
	lazyImages()

	if (!('CSS' in window) || !CSS.supports('object-fit', 'cover')) {
		objectFitImages()
	}

	// Load custom tracking code lazily, so it's non-blocking.
	import('./scripts/fonts/base.js').then((fonts) => fonts.init())
	import('./scripts/cookies.js').then((cookies) => cookies.init())
}

start()
